<template>
    <div class="container text-center my-5">
        <h2>{{ $t('aboutUs') }}</h2>
        <p>This page provides more details about our project and team.</p>
    </div>
</template>

<script>
export default {
    name: 'AboutView'
}
</script>
