<template>
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
        <div class="container-fluid">
            <a class="navbar-brand" href="/">{{ $t('projectName') }}</a>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarNav">
                <ul class="navbar-nav ms-auto">
                    <li class="nav-item">
                        <router-link class="nav-link" to="/">{{ $t('home') }}</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" to="/about">{{ $t('about') }}</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" to="/contact">{{ $t('contact') }}</router-link>
                    </li>
                    <li class="nav-item">
                        <button class="btn btn-outline-primary" @click="switchLanguage">{{ $t('languageSwitch') }}</button>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</template>

<script>
export default {
    name: 'PageNavbar',
    methods: {
        switchLanguage() {
            this.$i18n.locale = this.$i18n.locale === 'en' ? 'ru' : 'en'
        }
    }
}
</script>
