<template>
    <footer class="bg-light text-center py-3">
        <div class="container">
            <small>&copy; 2024 Shadexis. All rights reserved.</small>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'PageFooter'
}
</script>
