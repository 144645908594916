<template>
    <div class="container text-center my-5">
        <h1>{{ $t('projectName') }}</h1>
        <p>{{ $t('projectDescription') }}</p>
    </div>
</template>

<script>
export default {
    name: 'HomeView'
}
</script>
