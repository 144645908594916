import { createI18n } from 'vue-i18n'

const messages = {
  en: {
    projectName: 'Shadexis VPN',
    projectDescription: 'Shadexis VPN is a telegram bot that provides a secure VPN connection with speeds up to 10 Gbps. The service uses the VLESS REALITY protocol, which protects user data even if compromised. Connection is made through a bot: payment and key issuance occur directly without going to third-party sites. Shadexis VPN is suitable for both professionals who need a stable and secure connection and ordinary users.',
    telegramLink: 'Our Telegram bot',
    languageSwitch: 'Switch Language'
  },
  ru: {
    projectName: 'Shadexis VPN',
    projectDescription: 'Shadexis VPN — это телеграм-бот, обеспечивающий безопасное VPN-подключение со скоростью до 10 Гбит/с. Сервис использует протокол VLESS REALITY, который защищает данные пользователей даже в случае компрометации. Подключение осуществляется через бота: оплата и выдача ключей происходят напрямую без переходов на сторонние сайты. Shadexis VPN подходит как профессионалам, нуждающимся в стабильном и защищенном соединении, так и обычным пользователям.',
    telegramLink: 'Наш Telegram-бот',
    languageSwitch: 'Переключить язык'
  }
}

const i18n = createI18n({
  locale: 'en', // Устанавливаем язык по умолчанию
  messages
})

export default i18n
