<template>
  <div id="app">
    <PageNavbar />
    <router-view />
    <PageFooter />
  </div>
</template>

<script>
import PageNavbar from './components/PageNavbar.vue'
import PageFooter from './components/PageFooter.vue'

export default {
  name: 'App',
  components: {
    PageNavbar,
    PageFooter
  }
}
</script>

<style>
/* Основные стили приложения */
body {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  background-color: #f9f9f9;
}
</style>
