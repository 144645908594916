<template>
    <div class="container text-center my-5">
        <h2>{{ $t('contactUs') }}</h2>
        <p>
            <a :href="telegramLink" target="_blank" class="btn btn-primary">
                {{ $t('telegramLink') }}
            </a>
        </p>
    </div>
</template>

<script>
export default {
    name: 'ContactView',
    data() {
        return {
            telegramLink: 'https://t.me/shadexis_vpn_bot'
        }
    }
}
</script>
